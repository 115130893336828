<template>
  <v-dialog
    v-model="dialog"
    scrollable
    persistent
    max-width="500px"
    transition="dialog-transition"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        color="color3 color3Text--text"
        small text
        v-on="on"
        :loading="loading"
      >
        <v-icon>fas fa-sms</v-icon> Opt-in
      </v-btn>

    </template>
    <v-card>
      <v-toolbar color="color2 color2Text--text">
        <v-toolbar-title>SMS Opt In</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn
          color="color1 color1Text--text"
          @click.stop="dialog=false; reset()"
          small fab
        >
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
        <v-window v-model="window">
          <v-window-item :key="0">
            <v-card-text class="text-center">
              <div>In order to recieve text messages from us, we need to confirm your mobile phone number</div>
              <v-expand-transition>
                <div v-if="dialog && !isVerified">
                  <v-text-field
                    name="cellPhone"
                    :label="label"
                    v-model="phone"
                    v-mask="'(###) ###-####'"
                    :rules="$v.phone.$dirty ? [
                      () => $v.phone.required || 'A mobile phone number is required',
                      () => $v.phone.ValidUSPhone || 'A valid mobile phone number is required',
                    ] : []"
                    @blur="$v.phone.$touch()"
                    color="color3"
                    :disabled="disabled"
                    :placeholder="placeholder"
                    :solo="solo"
                  ></v-text-field>
                  <v-btn
                    color="success white--text"
                    @click.stop="checkNumber"
                    :disabled="$v.invalid || !validNumber || isVerified"
                    :loading="loading"
                  >
                    Send Code
                  </v-btn>
                </div>
              </v-expand-transition>
              <v-expand-transition>
                <div v-if="dialog && isVerified">
                  <v-alert type="success" :value="true" text prominent class="mt-2">
                    You're all set
                  </v-alert>
                  <v-btn color="success" @click.stop="dialog=false; reset()">ok</v-btn>
                </div>
              </v-expand-transition>
            </v-card-text>
          </v-window-item>
          <v-window-item :key="1">
            <v-card-text>
            <div class="title">Enter the verficiation code</div>
            <div v-if="!skip">We just sent a text message with a fresh verification code</div>
            <div class="caption">Note: only the last code issued will be valid and codes are only good for 15 minutes</div>
            <v-text-field
              label="Verification Code"
              v-model="vCode"
              color="color3"
              type="number"
              autofocus
            ></v-text-field>
            <v-alert type="error" :value="!!codeError" text prominent>
              {{codeError}}
            </v-alert>
            <div class="text-center pb-3">
              <v-btn
                color="color3 color3Text--text"
                :disabled="!validCode"
                @click.stop="verifyCode"
                :loading="loading"
              >Go</v-btn>
            </div>
            <div class="caption text-center">Didn't receive a code? <span class="clickable color3--text" @click.stop="reset">Try again.</span></div>
            </v-card-text>
          </v-window-item>
        </v-window>
        <v-card-actions>
          <v-fab-transition>
            <v-btn
              color="color3"
              class="mt-5"
              text x-small
              @click.stop="skipToCode"
              v-if="window === 0"
              :disabled="isVerified"
            >I already have a code</v-btn>
          </v-fab-transition>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mask } from 'vue-the-mask'
import { validationMixin } from 'vuelidate'
import { required } from 'vuelidate/lib/validators'
import ValidUSPhone from '@/helpers/ValidUSPhone'

export default {
  props: ['value', 'label', 'solo', 'disabled', 'placeholder', 'model'],
  directives: { mask },
  mixins: [validationMixin],
  validations () {
    return {
      phone: { required, ValidUSPhone }
    }
  },
  data () {
    return {
      dialog: false,
      verified: [],
      window: 0,
      phone: null,
      vCode: null,
      codeError: null,
      skip: false,
      load: []
    }
  },
  computed: {
    loading () {
      return this.load.length > 0
    },
    isVerified () {
      return this.verified.includes(this.cleanNumber)
    },
    validCode () {
      return this.vCode && this.vCode.length === 6
    },
    cleanNumber () {
      const x = this.phone
      return x ? x.replace(/\D/g, '') : null
    },
    validNumber () {
      return this.cleanNumber && ValidUSPhone(this.cleanNumber)
    }
  },
  methods: {
    reset () {
      this.window = 0
      this.vCode = null
      this.codeError = null
      this.skip = false
      this.phone = null
      this.verified = []
      this.$nextTick(() => { this.$v.$reset() })
    },
    skipToCode () {
      this.skip = true
      this.window = 1
    },
    btnClick () {
      if (this.isVerified) {
        this.onVerified()
        return
      }
      if (this.validNumber) {
        this.checkNumber()
        return
      }
      this.dialog = true
    },
    checkNumber () {
      if (!this.validNumber || this.isVerified) return
      this.load.push('check')
      this.$VBL.sms.check(this.cleanNumber)
        .then(r => {
          if (r.data === 'true') {
            this.verified.push(this.cleanNumber)
          } else {
            this.sendCode()
          }
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.load = this.load.filter(f => f !== 'check') })
    },
    sendCode () {
      this.load.push('send')
      this.$VBL.sms.sendCode(this.cleanNumber)
        .then(r => {
          this.window = 1
        })
        .catch(e => console.log(e.response))
        .finally(() => { this.load = this.load.filter(f => f !== 'send') })
    },
    verifyCode () {
      this.load.push('verify')
      this.$VBL.sms.verify(this.cleanNumber, this.vCode)
        .then(r => {
          this.verified.push(this.cleanNumber)
          this.$nextTick(() => { this.window = 0 })
        })
        .catch(e => {
          console.log(e)
          this.codeError = e.response.data.Message
        })
        .finally(() => { this.load = this.load.filter(f => f !== 'verify') })
    },
    onVerified () {
      this.$emit('verified', this.cleanNumber)
    }
  },
  watch: {
    isVerified: function (v) {
      if (v) {
        this.onVerified()
      }
    }
  }
}
</script>
